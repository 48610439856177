<template>
  <split-pane  :min-percent='10' :default-percent='12' split="vertical">
      <template slot="paneL">
        <el-input placeholder="请输入关键字进行过滤" v-model="filterText" size="medium"></el-input> 
        <el-tree :data="treeData" 
                  :props="defaultProps" 
                  @node-click="handleNodeClick" 
                  node-key="id"  
                  v-loading="loading"
                  element-loading-text="加载中"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(255, 251, 255, 0.8)"
                  :default-expanded-keys="['0']"                     
                  :style="{height: scrollerHeight,overflow:'auto',display: 'flex' }"
                  highlight-current
                  :filter-node-method="filterNode"
                  ref="eltree"
                  :expand-on-click-node="false" >
            <template v-slot="{node,data}">                                     
                <span v-if="data.id ==0 "  class="el-icon-notebook-1 nodeRoot" >
                  <span class="nodeLabel">{{ node.label}} </span>
                </span>                  
                <span v-else class="el-icon-folder" > 
                    <span class="nodeLabel">{{ node.label}} </span>
                </span>
            </template>
        </el-tree>        
      </template>
 
      <template slot="paneR">
        <div style="background:white;overflow-y:auto;height:90vh">
          <div class="one-toolbar"><span style="padding-left:20px;" class="spanRow">1、图片 <el-input placeholder="请输入名称后按回车键查询" @keyup.enter.native="imgQry" v-model="v_img" size="small" type="text" style="width:220px;margin-left:10px"></el-input></span>
          </div>
          <div style="display:flex; padding-left:30px;" >
            <div style="display:flex; min-height:155px;flex-wrap:wrap"  >
              <div v-for="(item,index) in imgData" :key="index" style="border:1px solid #389;width:152px;margin-right:5px;min-height:150px;margin-top:5px;margin-bottom:5px">  
                <el-image fit="scale-down"  style="width:140px;height:120px;margin-left:5px;" :src="item.FILEPATH"></el-image>
                <div style="height:25px;background:#e8eaec;text-overflow:ellipsis;width:150px;overflow:hidden" :title="item.FILENM">{{item.FILENM}}</div>
                <div style="height:25px;background:#e8eaec;width:150px;margin-top:1px; display:flex;justify-content:space-between">
                  <el-link  :underline="false" @click="imgDel(item.IDSEQ,item.LSTSEQ,'PIC',index)"><Icon type="md-close" /> 删除</el-link>
                  <el-link :underline="false" @click="imgPreview(item.FILEPATH)"><Icon type="ios-search" /> 预览</el-link>
                </div>
              </div>      
              <div  style="margin-left:20px;margin-top:50px;width:65px;height:65px;border:2px dashed #389">
                <el-link :underline="false" @click="upClick" >
                  <Upload
                    ref="upload"
                    name="upfile"
                    :disabled ="level!=='3'&&level!=='2'"
                    :data="uploadData"
                    :show-upload-list="false"
                    :before-upload="beforeImgFile"
                    :on-success="successImgFile"
                    :on-progress="handleProgress"
                    accept="png,jpg,jpeg"                        
                    :format="['jpg','jpeg','png','gif','webp' ]"
                    :max-size="10240"
                    :on-format-error="handleFormatError"
                    :on-exceeded-size="handleMaxSize"
                    :action="uploadUrl"
                    style="display: inline-block;width:80px; ">                           
                    <div style="padding: 1px 0;width:60px;height:60px;">
                        <el-image src="/assets/basedict/nopic.png"   ></el-image> 
                    </div>                                                       
                  </Upload> 
                </el-link>
              </div> 
            </div>   
         </div>   
         <!--2 上传PDF   -->
            <div class="one-toolbar"><span  style="padding-left:20px;" class="spanRow">2、PDF技术规格书</span></div>
            <div style="display:flex; padding-left:30px;" >
              <div style="display:flex; min-height:155px;flex-wrap:wrap"  >
                <div v-for="(item,index) in pdfArr" :key="index" style="border:1px solid #389;width:205px;margin-right:5px;min-height:190px;margin-top:5px;margin-bottom:5px">  
                    <div style="height:35px;background:#e8eaec;width:200px; display:flex;justify-content:center">
                        <el-link :underline="false" style="margin-right:100px;" @click="pdfViewer(item.FILEPATH)"><Icon type="ios-search" />查看</el-link>
                        <el-link  :underline="false" @click="pdfDel(item.IDSEQ,item.LSTSEQ,'PDF',index)"><Icon type="md-close" /> 删除</el-link>
                    </div> 
                    <div style="width:200px;height:150px;border:1px solid #e8eaec;overflow-y:hidden;margin-top:5px">
                        <iframe :src="item.FILEPATH" frameborder="0" style="width: 100%; height: 100%"></iframe>
                    </div> 
                    <div style="height:25px;background:#e8eaec;text-overflow:ellipsis;width:150px;overflow:hidden" :title="item.FILENM">{{item.FILENM}}</div>
                </div>           
                <div  style="margin-left:20px;margin-top:50px;width:65px;height:65px;border:2px dashed #389">
                  <el-link :underline="false"  @click="upClick" >
                    <Upload
                      ref="uppdf"
                      name="upfile"
                      :disabled ="level!=='3'&&level!=='2'"
                      :data="uploadData"
                      :show-upload-list="false"
                      :before-upload="beforePdfFile"
                      :on-success="successImgFile"
                      :on-progress="handleProgress"
                      accept="pdf"                        
                      :format="['pdf' ]"
                      :max-size="10240"
                      :on-format-error="pdfFormatError"
                      :on-exceeded-size="handleMaxSize"
                      :action="uploadUrl"
                      style="display: inline-block;width:80px; ">                           
                      <div style="padding: 1px 0;width:60px;height:60px;">
                          <el-image src="/assets/basedict/nopic.png"   ></el-image> 
                      </div>                                                       
                    </Upload> 
                  </el-link>    
              </div> 
            </div>
          </div>
          <!-- 上传视频 -->
         
          <div class="one-toolbar"><span  style="padding-left:20px;" class="spanRow">3、MP4视频</span></div>
          <div style="display:flex; padding-left:30px;" >
              <div style="display:flex; min-height:285px;flex-wrap:wrap"  >
                <div v-for="(item,index) in videoArr" :key="index" style="border:1px solid #389;width:355px;margin-right:5px;min-height:280px;margin-top:5px;margin-bottom:5px">  
                    <div style="height:35px;background:#e8eaec;width:350px; display:flex;justify-content:center">
                        <el-link  :underline="false" @click="videoDel(item.IDSEQ,item.LSTSEQ,item.FILEPATH,index)"><Icon type="md-close" /> 删除</el-link>
                    </div> 
                    <div style="height:25px;background:#e8eaec;margin-top:1px;text-overflow:ellipsis;width:350px;overflow:hidden" :title="item.FILENM">{{item.FILENM}}</div>
                    <div style="width:350px;height:250px;border:1px solid #e8eaec;overflow-y:hidden">  
                        <video-player v-if="videoArr.length>0"  class="video-player vjs-custom-skin" 
                                        ref="videoPlayer" 
                                        :playsline="false" 
                                        :options="videoConfig[index]">
                        </video-player>
                    </div>
                </div>            
                <div  style="margin-left:20px;margin-top:50px;width:65px;height:65px;border:2px dashed #389">
                  <el-link :underline="false"  @click="upClick" >
                    <Upload
                      ref="upvideo"
                      name="upfile"
                      :disabled ="level!=='3'&&level!=='2'"
                      :data="uploadData"
                      :show-upload-list="false"
                      :before-upload="beforeVideoFile"
                      :on-success="successImgFile"
                      :on-progress="handleProgress"
                      accept="mp4"                        
                      :format="['mp4' ]"
                      :max-size="20480"
                      :on-format-error="vdFormatError"
                      :on-exceeded-size="vdMaxSize"
                      :action="uploadUrl"
                      style="display: inline-block;width:80px; ">                           
                      <div style="padding: 1px 0;width:60px;height:60px;">
                          <el-image src="/assets/basedict/nopic.png"   ></el-image> 
                      </div>                                                       
                    </Upload> 
                  </el-link>    
              </div> 
            </div>
          </div>
          <!-- 4、包装图 -->
          
          <div class="one-toolbar"><span  style="padding-left:20px;" class="spanRow">4、包装图</span></div>
          <div style="display:flex; padding-left:30px;" >
              <div style="display:flex; min-height:155px;flex-wrap:wrap"  >
                <div v-for="(item,index) in pkgData" :key="index" style="border:1px solid #389;width:152px;margin-right:5px;min-height:150px;margin-top:5px;margin-bottom:5px">  
                  <el-image fit="scale-down"  style="width:140px;height:120px;margin-left:5px;" :src="item.FILEPATH"></el-image>
                  <div style="height:25px;background:#e8eaec;text-overflow:ellipsis;width:140px;overflow:hidden" :title="item.FILENM">{{item.FILENM}}</div>
                  <div style="height:25px;background:#e8eaec;width:150px;margin-top:1px; display:flex;justify-content:space-between">
                    <el-link  :underline="false" @click="imgDel(item.IDSEQ,item.LSTSEQ,'PKG',index)"><Icon type="md-close" /> 删除</el-link>
                    <el-link :underline="false" @click="imgPreview(item.FILEPATH)"><Icon type="ios-search" /> 预览</el-link>
                  </div>
                </div>      
                <div  style="margin-left:20px;margin-top:50px;width:65px;height:65px;border:2px dashed #389">
                  <el-link :underline="false" @click="upClick" >
                    <Upload
                      ref="upload"
                      name="upfile"
                      :disabled ="level!=='3'&&level!=='2'"
                      :data="uploadData"
                      :show-upload-list="false"
                      :before-upload="beforePkgFile"
                      :on-success="successImgFile"
                      :on-progress="handleProgress"
                      accept="png,jpg,jpeg"                        
                      :format="['jpg','jpeg','png','gif','webp' ]"
                      :max-size="10240"
                      :on-format-error="handleFormatError"
                      :on-exceeded-size="handleMaxSize"
                      :action="uploadUrl"
                      style="display: inline-block;width:80px; ">                           
                      <div style="padding: 1px 0;width:60px;height:60px;">
                          <el-image src="/assets/basedict/nopic.png"   ></el-image> 
                      </div>                                                       
                    </Upload> 
                  </el-link>
                </div> 
              </div>   
          </div> 
          <!-- 5/证书 -->
          
          <div class="one-toolbar"><span  style="padding-left:20px;" class="spanRow">5、证书</span></div>
          <div style="display:flex; padding-left:30px;" >
              <div style="display:flex; min-height:155px;flex-wrap:wrap"  >
                <div v-for="(item,index) in crtData" :key="index" style="border:1px solid #389;width:152px;margin-right:5px;min-height:150px;margin-top:5px;margin-bottom:5px">  
                  <el-image fit="scale-down"  style="width:140px;height:120px;margin-left:5px;" :src="item.FILEPATH"></el-image>
                  <div style="height:25px;background:#e8eaec;text-overflow:ellipsis;width:140px;overflow:hidden" :title="item.FILENM">{{item.FILENM}}</div>
                  <div style="height:25px;background:#e8eaec;width:150px;margin-top:1px; display:flex;justify-content:space-between">
                    <el-link  :underline="false" @click="imgDel(item.IDSEQ,item.LSTSEQ,'CERT',index)"><Icon type="md-close" /> 删除</el-link>
                    <el-link :underline="false" @click="imgPreview(item.FILEPATH)"><Icon type="ios-search" /> 预览</el-link>
                  </div>
                </div>      
                <div  style="margin-left:20px;margin-top:50px;width:65px;height:65px;border:2px dashed #389">
                  <el-link :underline="false" @click="upClick" >
                    <Upload
                      ref="upcert"
                      name="upfile"
                      :disabled ="level!=='3'&&level!=='2'"
                      :data="uploadData"
                      :show-upload-list="false"
                      :before-upload="beforeCrtFile"
                      :on-success="successImgFile"
                      :on-progress="handleProgress"
                      accept="png,jpg,jpeg"                        
                      :format="['jpg','jpeg','png','gif','webp' ]"
                      :max-size="10240"
                      :on-format-error="handleFormatError"
                      :on-exceeded-size="handleMaxSize"
                      :action="uploadUrl"
                      style="display: inline-block;width:80px; ">                           
                      <div style="padding: 1px 0;width:60px;height:60px;">
                          <el-image src="/assets/basedict/nopic.png"   ></el-image> 
                      </div>                                                       
                    </Upload> 
                  </el-link>
                </div> 
              </div>   
          </div>   
          <!--6 上传PDF报价表   -->
          
            <div class="one-toolbar"><span  style="padding-left:20px;" class="spanRow">6、PDF报价表</span></div>
            <div style="display:flex; padding-left:30px;" >
              <div style="display:flex; min-height:155px;flex-wrap:wrap"  >
                <div v-for="(item,index) in prcArr" :key="index" style="border:1px solid #389;width:205px;margin-right:5px;min-height:190px;margin-top:5px;margin-bottom:5px">  
                    <div style="height:35px;background:#e8eaec;width:200px; display:flex;justify-content:center">
                        <el-link :underline="false" style="margin-right:100px;" @click="pdfViewer(item.FILEPATH)"><Icon type="ios-search" />查看</el-link>
                        <el-link  :underline="false" @click="pdfDel(item.IDSEQ,item.LSTSEQ,'PRC',index)"><Icon type="md-close" /> 删除</el-link>
                    </div> 
                    <div style="width:200px;height:150px;border:1px solid #e8eaec;overflow-y:hidden;margin-top:5px">
                        <iframe :src="item.FILEPATH" frameborder="0" style="width: 100%; height: 100%"></iframe>
                    </div> 
                    <div style="height:25px;background:#e8eaec;text-overflow:ellipsis;width:200px;overflow:hidden" :title="item.FILENM">{{item.FILENM}}</div>
                </div>           
                <div  style="margin-left:20px;margin-top:50px;width:65px;height:65px;border:2px dashed #389">
                  <el-link :underline="false"  @click="upClick" >
                    <Upload
                      ref="upprc"
                      name="upfile"
                      :disabled ="level!=='3'&&level!=='2'"
                      :data="uploadData"
                      :show-upload-list="false"
                      :before-upload="beforePrcFile"
                      :on-success="successImgFile"
                      :on-progress="handleProgress"
                      accept="pdf"                        
                      :format="['pdf' ]"
                      :max-size="10240"
                      :on-format-error="pdfFormatError"
                      :on-exceeded-size="handleMaxSize"
                      :action="uploadUrl"
                      style="display: inline-block;width:80px; ">                           
                      <div style="padding: 1px 0;width:60px;height:60px;">
                          <el-image src="/assets/basedict/nopic.png"   ></el-image> 
                      </div>                                                       
                    </Upload> 
                  </el-link>    
              </div> 
            </div>
          </div>
          <!--7 上传PDF目录册   -->
 
            <div class="one-toolbar"><span  style="padding-left:20px;" class="spanRow">7、PDF目录册</span></div>
            <div style="display:flex; padding-left:30px;" >
              <div style="display:flex; min-height:155px;flex-wrap:wrap"  >
                <div v-for="(item,index) in dirArr" :key="index" style="border:1px solid #389;width:205px;margin-right:5px;min-height:190px;margin-top:5px;margin-bottom:5px">  
                    <div style="height:35px;background:#e8eaec;width:200px; display:flex;justify-content:center">
                        <el-link :underline="false" style="margin-right:100px;" @click="pdfViewer(item.FILEPATH)"><Icon type="ios-search" />查看</el-link>
                        <el-link  :underline="false" @click="pdfDel(item.IDSEQ,item.LSTSEQ,'DIR',index)"><Icon type="md-close" /> 删除</el-link>
                    </div> 
                    <div style="width:200px;height:150px;border:1px solid #e8eaec;overflow-y:hidden;margin-top:5px">
                        <iframe :src="item.FILEPATH" frameborder="0" style="width: 100%; height: 100%"></iframe>
                    </div> 
                    <div style="height:25px;background:#e8eaec;text-overflow:ellipsis;width:200px;overflow:hidden" :title="item.FILENM">{{item.FILENM}}</div>
                </div>           
                <div  style="margin-left:20px;margin-top:50px;width:65px;height:65px;border:2px dashed #389">
                  <el-link :underline="false"  @click="upClick" >
                    <Upload
                      ref="updir"
                      name="upfile"
                      :disabled ="level!=='3'&&level!=='2'"
                      :data="uploadData"
                      :show-upload-list="false"
                      :before-upload="beforeDirFile"
                      :on-success="successImgFile"
                      :on-progress="handleProgress"
                      accept="pdf"                        
                      :format="['pdf' ]"
                      :max-size="10240"
                      :on-format-error="pdfFormatError"
                      :on-exceeded-size="handleMaxSize"
                      :action="uploadUrl"
                      style="display: inline-block;width:80px; ">                           
                      <div style="padding: 1px 0;width:60px;height:60px;">
                          <el-image src="/assets/basedict/nopic.png"   ></el-image> 
                      </div>                                                       
                    </Upload> 
                  </el-link>    
              </div> 
            </div>
          </div>
          <el-dialog :visible.sync="showViewer" width="70vh" append-to-body>
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
              <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">预览</span>
            </div>
            <el-image fit="scale-down"  style=" margin:5px auto;height:500px;width:100% " :src="listurl"></el-image>
          </el-dialog>
          <!-- 上传进度 -->
          <el-dialog :visible.sync="videoFlag" width="150px" append-to-body :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
              <div slot="title" :style="{'background':'#409eff','color':'white','height':'30px'}">
                  <span style="line-height:30px;height:30px;font-size:16px;padding-left:15px;">正在上传中...</span>
              </div>
              <el-progress   type="circle" :percentage="videoUploadPercent"  ></el-progress>  
          </el-dialog>
          <el-dialog :visible.sync="promptWin" :show-close="false" width="450px" append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">提示</span>
            </div>
            <div   >{{prompt}} </div>
            <span slot="footer"  :style="{'height':'40px' }">
                <el-button size="small" type="primary" @click="promptWin = false">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </template>  
  </split-pane>
</template>

<script>
import { getTabColOrData,getBaseData } from '../../api/user' 
export default {
  name:"prdtype_docmgt",
  data() {
    return {
      listurl:'',//放大的图片
      loading:false,
      videoConfig:[], 
      uploadData:{}, //上传数据
      videoUploadPercent:0,
      videoFlag:false,
      promptWin:false,
      treeData:[] ,//  树数据
      propCol:[], //属性列（表格隐藏列）
      prompt:'',
      propRow:{},
      v_parent:'',
      crtData:[],
      showViewer:false,//打开预览/关闭
      selparam:'',// select 绑定值
      videoArr:[],
      textparam:'', // input 绑定值
      pdfArr:[],
      prcArr:[],
      dirArr:[],
      imgData:[],
      pkgData:[],
      defaultProps: 
      {  //el-tree 默认字段属性
          children: 'children',
          id:'id',
          icon:'icon',
          label: 'label',
          parentid:'parentid'
      },
      treeid :'0',
      level:'1',
      nodeLabel:'',
      filterText: '',
      propData:[] , //类别属性数据
      dtlData:[] , //类别属性规格数据
      v_url:this.$store.state.queryUrl , //api请求路径     
      v_likeurl:this.$store.state.baseUrl+'sysprivs/getLikeManyRec', //多记录查询 like模式    
      v_username:this.$cookies.get('v_username'), //api 请求用户
      v_password:this.$cookies.get('v_password') ,//api 请求密码
      v_img:'',
      uploadUrl:this.$store.state.baseUrl +'oss/fileUpload?username='+this.$cookies.get('v_username')+'&password='+this.$cookies.get('v_password')
    }
  },
  watch: {
    filterText(val) {
      this.$refs.eltree.filter(val);
    }
  },
  created() {
    this.getData()
  },
  computed: {
    scrollerHeight: function() {
        return (window.innerHeight - 130) + 'px';
    }, 
    subHeight: function() {
        return (window.innerHeight - 480) + 'px';
    }, 
  },
  methods: {
    imgQry(){
       let cond =' and lower(filenm) like lower(\'%'+this.v_img+'%\')'
       this.qryImgData(this.treeid,cond)
    },
    upClick(){
      if (this.level !=='3'&&this.level !=='2'){
       this.promptWin=true
       this.prompt ='请选中第二级或第三级操作'
      }
    },
    getVideoList(){      
        for(let k=0;k<this.videoArr.length;k++){
            this.videoConfig.push({
                playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                autoplay: false, // 如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                    src: this.videoArr[k].FILEPATH // url地址 /assets/authorize/tmp4.mp4
                }],
                poster: '', // 你的封面地址
                //width:'100%',
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true // 全屏按钮
                }
            })
        }        
    },
    //预览PDF
    pdfViewer(filePath){
        let routeUrl = this.$router.resolve({
            path: '/fullPreview',
            query: {fileUrl:filePath},
        });
        window.open(routeUrl.href, '_blank');
    },
    //文件超出指定文件大小限制的钩子
    handleMaxSize (file) {
        this.prompt =file.name + ' 文件太大, 不能超出10M'
        this.promptWin=true
    },
    vdMaxSize (file) {
        this.prompt =file.name + ' 文件太大, 不能超出20M'
        this.promptWin=true
    },
    //文件格式验证错误时的钩子
    handleFormatError (file) {
        this.prompt='文件格式错误,'+file.name + ' 不正确,请选择图片格式' 
        this.promptWin=true
    },
    pdfFormatError (file) {
        this.prompt='文件格式错误,'+file.name + ' 不正确,请选择PDF格式' 
        this.promptWin=true
    },
    vdFormatError (file) {
        this.prompt='文件格式错误,'+file.name + ' 不正确,请选择MP4格式' 
        this.promptWin=true
    },
    //上传显示进度
    handleProgress(event,file,fileList){
        this.videoFlag = true
        this.videoUploadPercent = Math.floor(event.percent)
    },
    // 文件上传成功时的钩子
    successImgFile(res,file){
        this.videoFlag = false
        this.videoUploadPercent=0
        this.qryImgData(this.treeid,'')
    },
    //上传文件之前的钩子
    beforeImgFile (res, file) {     
        this.uploadData = {
            p_idseq: this.treeid ,
            p_table:'PROP_FILE',
            p_filety:'IMG',
            p_parent: this.v_parent,
            p_user:this.v_username
        }
        let promise = new Promise(resolve => {
        this.$nextTick(function() {
                resolve(true);
            });
        });
        return promise;  
    },
    beforePkgFile (res, file) {     
        this.uploadData = {
            p_idseq: this.treeid ,
            p_table:'PROP_FILE',
            p_filety:'PKG',
            p_parent: this.v_parent,
            p_user:this.v_username
        }
        let promise = new Promise(resolve => {
        this.$nextTick(function() {
                resolve(true);
            });
        });
        return promise;  
    },
    beforeCrtFile (res, file) {     
        this.uploadData = {
            p_idseq: this.treeid ,
            p_table:'PROP_FILE',
            p_filety:'CERT',
            p_parent: this.v_parent,
            p_user:this.v_username
        }
        let promise = new Promise(resolve => {
        this.$nextTick(function() {
                resolve(true);
            });
        });
        return promise;  
    },
    beforePdfFile (res, file) {     
        this.uploadData = {
            p_idseq: this.treeid ,
            p_table:'PROP_FILE',
            p_filety:'PDF',
            p_parent: this.v_parent,
            p_user:this.v_username
        }
        let promise = new Promise(resolve => {
        this.$nextTick(function() {
                resolve(true);
            });
        });
        return promise;  
    },
    beforePrcFile (res, file) {     
        this.uploadData = {
            p_idseq: this.treeid ,
            p_table:'PROP_FILE',
            p_filety:'PRC',
            p_parent: this.v_parent,
            p_user:this.v_username
        }
        let promise = new Promise(resolve => {
        this.$nextTick(function() {
                resolve(true);
            });
        });
        return promise;  
    },
    beforeDirFile (res, file) {     
        this.uploadData = {
            p_idseq: this.treeid ,
            p_table:'PROP_FILE',
            p_filety:'DIR',
            p_parent: this.v_parent,
            p_user:this.v_username
        }
        let promise = new Promise(resolve => {
        this.$nextTick(function() {
                resolve(true);
            });
        });
        return promise;  
    },
    beforeVideoFile (res, file) {     
        this.uploadData = {
            p_idseq: this.treeid ,
            p_table:'PROP_FILE',
            p_filety:'VIDEO',
            p_parent: this.v_parent,
            p_user:this.v_username
        }
        let promise = new Promise(resolve => {
        this.$nextTick(function() {
                resolve(true);
            });
        });
        return promise;  
    },
    imgPreview(filepath){
      this.showViewer =true
      this.listurl =filepath
    },
    //删除 图片/PDF /VIDEO
    imgDel(idseq,lstseq,type,index){
        this.$confirm('你确定要删除此图片吗?','提示',{
            confirmButtonText: '确定',
            cancelButtonText:'取消',
            customClass:'messageBox',
            showClose:false,
            type:'info',
        }).then(()=>{
            //发起删除请求:把状态变更为N ,不是真正意义上的删除    
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'user/updateRec?username='+this.v_username+'&password='+this.v_password,
                data: {p_key:'idseq',p_value:idseq,p_key2:'lstseq',p_value2:lstseq,p_table:'prop_file',p_cond:' set  stfg=\'N\'' },
            }).then(res=>{  
              if (type=='PIC'){
                this.imgData.splice(index, 1); 
              }else if (type=='CERT'){
                this.crtData.splice(index, 1); 
              }else{
                this.pkgData.splice(index, 1)
              }
            })
            
        }).catch(()=>{})
    },
    pdfDel(idseq,lstseq,type,index){
        this.$confirm('你确定要删除此PDF文件吗?','提示',{
            confirmButtonText: '确定',
            cancelButtonText:'取消',
            customClass:'messageBox',
            showClose:false,
            type:'info',
        }).then(()=>{
            //发起删除请求:把状态变更为N ,不是真正意义上的删除    
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'user/updateRec?username='+this.v_username+'&password='+this.v_password,
                data: {p_key:'idseq',p_value:idseq,p_key2:'lstseq',p_value2:lstseq,p_table:'prop_file',p_cond:' set  stfg=\'N\'' },
            }).then(res=>{  
              if (type=='PDF'){
                this.pdfArr.splice(index, 1); 
              }else if (type=='PRC'){
                this.prcArr.splice(index, 1)
              }else if (type=='DIR'){
                this.dirArr.splice(index, 1)
              }
            })
            
        }).catch(()=>{})
    },
    videoDel(idseq,lstseq,path,index){
        this.$confirm('你确定要删除此视频文件吗?','提示',{
            confirmButtonText: '确定',
            cancelButtonText:'取消',
            customClass:'messageBox',
            showClose:false,
            type:'info',
        }).then(()=>{
            //发起删除请求:把状态变更为N ,不是真正意义上的删除    
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'user/updateRec?username='+this.v_username+'&password='+this.v_password,
                data: {p_key:'idseq',p_value:idseq,p_key2:'lstseq',p_value2:lstseq,p_table:'prop_file',p_cond:' set  stfg=\'N\'' },
            }).then(res=>{  
              this.videoArr.splice(index, 1); 
            })
            
        }).catch(()=>{})
    },
    //查询图片数据
    qryImgData(idseq,cond){
        getTabColOrData(this.v_url,this.v_username,this.v_password,'V_PROP_FILE','idseq',idseq,'STFG','Y','','',cond).then((res) => {
            this.imgData=[]
            this.pdfArr=[]
            this.prcArr=[]
            this.dirArr=[]
            this.videoArr=[]
            this.pkgData=[]
            this.crtData=[]
            for (let k=0; k<res.data.length;k++){
                if (res.data[k].FILETY==='IMG'){
                    this.imgData.push(res.data[k])
                }else if (res.data[k].FILETY==='PKG'){
                    this.pkgData.push(res.data[k])
                }else if (res.data[k].FILETY==='CERT'){
                    this.crtData.push(res.data[k])
                }else if (res.data[k].FILETY==='PDF'){
                    this.pdfArr.push(res.data[k])
                }else if (res.data[k].FILETY==='PRC'){
                    this.prcArr.push(res.data[k])
                }else if (res.data[k].FILETY==='DIR'){
                    this.dirArr.push(res.data[k])
                }else if (res.data[k].FILETY==='VIDEO'){
                    this.videoArr.push(res.data[k])
                    this.getVideoList()
                }    
            }
        })
    },
 
 
    // 获取 树形控件数据
    getData() {  
        var vm=this;
        vm.loading=true
        this.$axios({
            method: 'post',
            url:vm.$store.state.baseUrl+'sysprivs/getElTreeData?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
            data: {p_table: 'V_DOC_CATEGORY'}
        }).then(function(res){                  
            vm.treeData =res.data;   
            if(res.data.length > 0 ){
                //'nextTick()' 下次dom更新时触发回调函数
                vm.$nextTick().then(() =>{
                    //使用setCurrentKey方法的时候必须将其放在nextTick中,要等数据更新渲染完再调用,否则找不到对应元素
                    vm.$refs.eltree.setCurrentKey(vm.treeid)
                })
            }     
            vm.loading =false          
        })                              
    },
    //节点点击
    handleNodeClick(data){
        this.nodeLabel=data.label;            
        this.treeid =data.id
        this.v_parent =data.parentid
        this.qryImgData(this.treeid,'')
        this.level =data.icon
        console.log('lvl:'+this.level+';treeid:'+this.treeid,data.parentid)
    },

    //节点过滤
    filterNode(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
    },
 
  }
}
</script>
 
<style lang="less">
  .el-dialog__body{
        padding: 15px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    .el-table {
        th {
            padding: 0 ;
        }
        thead{
            height: 15px !important;
            color:rgb(128,128, 128);
            font-size:12px;
        }
        td {
            padding: 5px ;
            font-size:11px;
        }   
    }
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb;
        
    }

</style>